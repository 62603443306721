import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import AuthStatus from '../components/auth/AuthStatus';
import './css/Layout.css'; // Import the CSS file
import { useAuth } from "../components/auth/AuthContext";
import Member from '../components/membership/Member';
import Api from '../helpers/Api';
import axios from 'axios';
function Layout() {
  let auth = useAuth();

  // var openMoneyBox = async()=>{
  //   try {
  //       const usbDevices = await navigator.usb.getDevices();
  //       const usbDevice = usbDevices[0]; // Assuming the USB printer is the first device

  //       if (!usbDevice) {
  //           console.log('No USB device found');
  //           const filters = [{ vendorId: 0x0483, productId: 0x070B }, { vendorId: 0x1FC9 }]; // Replace with actual vendorId of the printer
  //           const options = { filters };
  //           const usbDevice = await navigator.usb.requestDevice(options);
  //       }

  //       await usbDevice.open();
  //       await usbDevice.selectConfiguration(1);
  //       await usbDevice.claimInterface(0);

  //       var openMoneyBoxBytes =  new Uint8Array([27, 112, 0, 25, 250]);// Replace with the actual open money box bytes

  //       await usbDevice.transferOut(1, openMoneyBoxBytes);
  //       console.log('Open money box command sent to USB printer');

  //       await usbDevice.releaseInterface(0);
  //       await usbDevice.close();

  //   } catch (error) {
  //       console.log('Error opening money box:', error);
  //   }
  // }

  const handleOpenDrawer = async () => {
    try {
      const response = await fetch(Api.OPEN_MONEY_BOX, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        console.log('open money drawer job sent successfully!');
      } else {
        console.error('Error sending open money drawer job:', response.statusText);
        alert(`Failed to send open money drawer job.]\nError: ${response.statusText}`);
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while sending the open money drawer job.');
    }
  }
  return (
    <div className="layout-container">
      <div className="page-container">
        <div className="info-status-container">
          <Member />
          <AuthStatus />
        </div>
        <div className="nav-menu">
          <ul>
            {/* <li className="nav-item">
              <Link to="/" className="nav-link">Public Page</Link>
            </li> */}
            <li className="nav-item">
              <Link to="/protected" className="nav-link">首頁</Link>
            </li>
            <li className="nav-item">
              <Link to="/order" className="nav-link">訂單</Link>
            </li>
            <li className="nav-item">
                  <Link to="/history" className="nav-link">歷史</Link>
            </li>
            {auth.user && auth.user.role==='admin'?
              <>
                <li className="nav-item">
                  <Link to="/account" className="nav-link">帳務</Link>
                </li>  
              </>:null
            }
            {auth.user && auth.user.role==="admin"?
              <li className="nav-item">
                <Link to="/admin" className="nav-link">後台</Link>
              </li>:null}
            {auth.user && auth.user.role?
            <li className="nav-item">
              <button className="nav-link"  onClick={handleOpenDrawer}>錢箱</button>
            </li>:null}
          </ul>
        </div>
        <div className="outlet-container">
          <Outlet />
        </div> 
      </div>
    </div>
  );
}

export default Layout;
