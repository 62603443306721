// Options.js
import React from 'react';
import './css/Options.css';

const Options = ({ isOpen, children }) => {
console.log(isOpen)
  if (!isOpen) {
    return null;
  }

  return (
    <div className="Options-overlay">
      <div className="Options">
        {/* <button className="close-button" onClick={onClose}>Close</button> */}
        {children}
      </div>
    </div>
  );
};

export default Options;
