const ip = "192.168.0.109"
const port = "3001"
const mod = 'prod';
const URL = mod === 'dev' ? 'https://dev.api.chang-cat.com' : 'https://apibao.chang-cat.com';
const PrinterUrl = "http://localhost:8080"
// const URL = `http://${ip}:${port}`;
const Api = {
    AUTH : `${URL}/api/auth/login`,
    GET_MENU : `${URL}/api/menu`,
    GET_IMG : `${URL}/api/image`,
    INSERT_LOG: `${URL}/api/log`,
    UPDATE_MENU: `${URL}/api/menu/updateMenu`,
    INSERT_ORDER: `${URL}/api/order/store_order`,
    GET_CALL_NUM: `${URL}/api/order/getCallNum`,
    GET_ID: `${URL}/api/getID`,
    GET_TOTAL_PRICE_TODAY: `${URL}/api/order/getTotalPriceToday`,
    GET_ORDER_HISTORY: `${URL}/api/order/getOrderHistory`,
    OPEN_MONEY_BOX: `${PrinterUrl}/opencashdrawer`,
    PRINT_ORDER: `${PrinterUrl}/print`,
}
/* 
const response = await fetch('http://localhost:8080/print', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(printData),
      });
*/
export default Api;
