import React from 'react';
import './css/Marquee.css';

const Marquee = () => {
    const marqueeText = "";
    return (
    <div className="marquee">
        <div className="marquee-content">
        {marqueeText}
        </div>
    </div>
    );
};

export default Marquee;
