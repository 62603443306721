import Api from '../helpers/Api';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './css/Admin.css';
import { useAuth } from "../components/auth/AuthContext";
import { useNavigate } from 'react-router-dom';

const ITEMS_PER_PAGE = 8; // 4 columns * 2 rows

const Admin = () => {
  const [menuData, setMenuData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedTab, setSelectedTab] = useState('套餐');
  const [editedItemId, setEditedItemId] = useState(null);
  const [editedImage, setEditedImage] = useState(null);

  let auth = useAuth();
  let navigate = useNavigate();

  useEffect(() => {
    axios
      .get(Api.GET_MENU, {
        headers: {
          Authorization: `Bearer ${auth.user.access_token}`,
        },
      })
      .then((response) => {
        setMenuData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching menu data:', error);
        auth.signout(() => navigate("/"));
      });
  }, []);

  // Calculate the start and end indexes for the current page
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;

  // Get the items for the current page and selected tab
  const currentItems = menuData && menuData[selectedTab] ? menuData[selectedTab].slice(startIndex, endIndex) : [];

  // Function to handle page navigation
  const goToNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const goToPreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  // Function to handle tab selection
  const selectTab = (tab) => {
    setSelectedTab(tab);
    setCurrentPage(1); // Reset to the first page when changing tabs
  };

  // Function to handle editing an item
  const handleEditItem = (item) => {
    setEditedItemId(item.id); // Update the editedItemId with the id of the item being edited
    setEditedImage(null);
  };

  // Function to handle file upload
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setEditedImage(file);
  };

  // Function to handle input changes for name and price
  const handleInputChange = (event) => {
    // Check if an item is being edited
    if (editedItemId) {
      const { name, value } = event.target;
      setMenuData((prevData) => {
        const updatedMenuData = { ...prevData };
        updatedMenuData[selectedTab] = updatedMenuData[selectedTab].map((item) =>
          item.id === editedItemId ? { ...item, [name]: value } : item
        );
        return updatedMenuData;
      });
    }
    // If no item is being edited, ignore the input changes
  };

  // Function to save the edited item
  const saveEditedItem = () => {
    console.log("click")
    const editedItem = menuData[selectedTab].find((item) => item.id === editedItemId);
    if (!editedItem) return; // Just in case, handle the scenario where the editedItem is not found

    // Create a new FormData instance to handle the file upload
    const formData = new FormData();
    formData.append('id', editedItem.id);
    formData.append('name', editedItem.name);
    formData.append('price', editedItem.price);
    formData.append('image', editedImage);
    
    axios
      .post(Api.UPDATE_MENU, formData, {
        headers: {
          Authorization: `Bearer ${auth.user.access_token}`,
        },
      }) // Replace '/api/updateItem' with your actual API endpoint for updating the item
      .then((response) => {
        // Update the menu data with the updated item
        const updatedMenuData = { ...menuData };
        updatedMenuData[selectedTab] = updatedMenuData[selectedTab].map((item) =>
          item.id === editedItem.id ? response.data : item
        );
        setMenuData(updatedMenuData);
        // Clear the edited item and image
        setEditedItemId(null);
        setEditedImage(null);
      })
      .catch((error) => {
        console.error('Error saving the edited item:', error);
        auth.signout(() => navigate("/"));
      });
  };

  return (
    <div className="admin-container">
      {/* Tab labels */}
      <div className="tab-labels">
        {menuData &&
          Object.keys(menuData).map((tab) => (
            <button
              key={tab}
              className={selectedTab === tab ? 'active' : ''}
              onClick={() => selectTab(tab)}
            >
              {tab}
            </button>
          ))}
      </div>

      {/* ... */}
      {/* Display the items for the current page */}
      <div className="menu-items">
        {currentItems.map((item) => (
          <div key={item.id} className={`menu-item ${item.id === editedItemId ? 'editing' : ''}`}>
            {item.id === editedItemId ? (
              <div className="edit-form">
                <input
                  type="text"
                  name="name"
                  value={item.name}
                  placeholder="Name"
                  onChange={handleInputChange}
                />
                <label for="price">Price:</label>
                <input
                  id='price'
                  type="number"
                  name="price"
                  value={item.price}
                  placeholder="Price"
                  onChange={handleInputChange}
                />
                <input type="file" accept="image/jpeg" onChange={handleFileUpload} />
                <button onClick={saveEditedItem}>Save</button>
              </div>
            ) : (
              <>
                <img
                  src={`${Api.GET_IMG}/${item.image_path}`}
                  // src={`${Api.GET_IMG}/chicken_caesar_wrap.jpg`}
                  
                  alt={`Image ${item.id}`}
                  className="menu-item-image"
                />
                <div className='menu-item-name'>{item.name}</div>
                <p className='menu-item-price'>Price: ${item.price}</p>
                <button onClick={() => handleEditItem(item)}>編輯</button>
              </>
            )}
          </div>
        ))}
        {/* Add empty items to fill the remaining space */}
        {currentItems.length < ITEMS_PER_PAGE &&
          Array.from({ length: ITEMS_PER_PAGE - currentItems.length }).map((_, index) => (
            <div key={`empty-${index}`} className="empty-item"></div>
          ))}
      </div>

      <div className="pagination">
        <button onClick={goToPreviousPage} disabled={currentPage === 1}>
          Previous Page
        </button>
        <button onClick={goToNextPage} disabled={currentItems.length < ITEMS_PER_PAGE}>
          Next Page
        </button>
      </div>


    </div>
  );
};

export default Admin;
