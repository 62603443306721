import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import "./auth.css"
export default function AuthStatus() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  useEffect(() => {
    const profile = JSON.parse(localStorage.getItem('auth'))
    setUser(profile);
  }, [localStorage.getItem('auth')]);

  if (!auth.user) {
    return (
      <div className="auth-status">
        <p>請先登入</p>
      </div>
    );
  }
  return (
    <div className="auth-status">
      <p>Hi, {user && user.username}
      <button
        onClick={() => {
          setUser('');
          auth.signout(() => navigate("/"));
        }}
      >
        登出
      </button></p>
    </div>
  );
}
