import React, { useState, useEffect } from 'react';
import { useAuth } from '../components/auth/AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';
import './css/Login.css';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLogin, setIsLogin] = useState(false);
  let navigate = useNavigate();
  let auth = useAuth();
  let location = useLocation();
  let from = location.state?.from?.pathname || '/protected';

  useEffect(() => {
    const storedAuth = localStorage.getItem('auth');
    setIsLogin(storedAuth ? true : false);
  }, [localStorage.getItem('auth')]);

  const handleLogin = (event) => {
    event.preventDefault();

    if (username && password) {
      auth.signin(username, password, (status) => {
        if (status === true) {
          navigate(from, { replace: true });
        } else {
          alert('Username or password error');
        }
      });
    } else {
      alert('Username or password cannot be null');
    }
  };

  return (
    <>
      {!isLogin ? (
        <div className="login-container">
          <form className="login-form" onSubmit={handleLogin}>
            <input
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button type="submit">Submit</button>
          </form>
        </div>
      ) : null}
    </>
  );
}

export default Login;
