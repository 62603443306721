import React,{ useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
const Remark = ({formValues, handleInputChange}) => {
  
  return (
    <div className="From-remark">
        <Form.Group>
        <Form.Control
            className="custom-remark"
            type="text"
            placeholder="備註"
            value={formValues.Remark}
            onChange={handleInputChange}
        />
        </Form.Group>
    </div>

  );
};

export default Remark;
