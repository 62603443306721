import React, { useState } from 'react';

const Amount = ({ handleAdd, handleSubtract, formValues }) => {

  return (
    <div className="Amount-container">
      <button className="Amount-btn" type="button" onClick={handleSubtract}>-</button>
      <input className="Amount-input" type="number" value={formValues.Amount} readOnly />
      <button className="Amount-btn" type="button" onClick={handleAdd}>+</button>
    </div>
  );
};

export default Amount;
