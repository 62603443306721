import React, {useState} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Routes, Route } from 'react-router-dom';
import './App.css'; // Import the CSS file
import Home from './pages/Home';
import Login from './pages/Login';
import Layout from './pages/Layout';
import RequireAuth from './components/auth/RequireAuth';
import RequireAdmin from './components/auth/RequireAdmin';
import AuthProvider from './components/auth/AuthProvider';
import Order from './pages/Order'
import Admin from './pages/Admin'
import Account from './pages/Account';
import History from './pages/History';
function App() {
  const [cartItems, setCartItems] = useState([]); // Shopping cart items
  return (
    <Container className="app-container">
      <Row className="app-row">
        <Col className="app-col">
          <AuthProvider>
            <Routes>
              <Route element={<Layout />}>
                <Route path="/" element={<Login />} />
                <Route
                  path="protected"
                  element={
                    <RequireAuth>
                      <Home setCartItems={setCartItems} cartItems={cartItems}/>
                    </RequireAuth>
                  }
                />
                <Route
                  path="order"
                  element={
                    <RequireAuth>
                      <Order setCartItems={setCartItems} cartItems={cartItems}/>
                    </RequireAuth>
                  }
                />
                <Route
                  path="account"
                  element={
                    <RequireAuth>
                      <Account/>
                    </RequireAuth>
                  }
                />
                <Route
                  path="history"
                  element={
                    <RequireAuth>
                      <History/>
                    </RequireAuth>
                  }
                />
                <Route
                  path="admin"
                  element={
                    <RequireAuth>
                      <RequireAdmin>
                        <Admin setCartItems={setCartItems} cartItems={cartItems}/>
                      </RequireAdmin>
                    </RequireAuth>
                  }
                />
              </Route>
            </Routes>
          </AuthProvider>
        </Col>
      </Row>
      <div className="footer">
          © 2023 Power by AllenChen. All rights reserved.
      </div>
    </Container>
  );
}

export default App;
