import  React from "react";
import {
  useLocation,
  Navigate,
} from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { useAuth } from "./AuthContext";
export default function RequireAuth({ children }) {
  let auth = useAuth();
  let location = useLocation();
  const isTokenExpired = (token) => {
    if (!token) return true;
    const { exp } = jwtDecode(token);
    return Date.now() >= exp * 1000; // Convert to milliseconds
  };
  if (!auth.user || isTokenExpired(auth.user.access_token)) {
    auth.signout(()=>{});
    return (<Navigate to="/" state={{ from: location }} replace />);
  }
  return children;
}