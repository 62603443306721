import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./member.css"
export default function Member() {
  const navigate = useNavigate();

  useEffect(() => {
    
  },[]);

  return (
    <div className="member-status">
        <p>Hi 會員</p>
    </div>
  );
}
