import React,{ useEffect, useState } from 'react';
import Amount from '../options/Amount';
import Remark from '../options/Remark';
const Default_Options = ( { handleAdd, handleSubtract, formValues, handleInputChange } ) => {
  return (
    <>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <Amount
            handleAdd={ handleAdd }
            handleSubtract={ handleSubtract }
            formValues = { formValues }
        />
        <Remark formValues = { formValues }  handleInputChange = { handleInputChange }/>
    </>
  );
};

export default Default_Options;
