import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ImageRenderer from './ImageRenderer.js';
import Api from '../helpers/Api'
import { useAuth } from "../components/auth/AuthContext";
import { useNavigate } from 'react-router-dom';
function Home({setCartItems, cartItems}) {
  let navigate = useNavigate();
  const [menuData, setMenuData] = useState(null);
  let auth = useAuth();
  useEffect(() => {
    axios.get(Api.GET_MENU, {
      headers: {
        Authorization: `Bearer ${auth.user.access_token}`,
      },
    })
      .then(response => {
        setMenuData(response.data);
      })
      .catch(error => {
        console.error('Error fetching menu data:', error);
        auth.signout(() => navigate("/"));
      });
  }, []);

  if (!menuData) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <ImageRenderer menuData={menuData} setCartItems={setCartItems} cartItems={cartItems}/>
    </>
  );
}

export default Home;
