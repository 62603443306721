import React, { useState, useEffect } from 'react';
import './css/Order.css';
import Api from '../helpers/Api'
import { useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import EPToolkit from '../helpers/escpos/escpos';
import { useAuth } from "../components/auth/AuthContext";

function Order({ cartItems, setCartItems }) {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [editItem, setEditItem] = useState(null);
  const [paymentValue, setPaymentValue] = useState('cash');
  const [userPay, setUserPay] = useState(null)
  const [takeOut, setTakeOut] = useState(false)
  const [callNum, setCallNum] = useState(null)
  const [nidinInfo, setNidinInfo] = useState(null)
  const [getID, setID] = useState(null)
  const [uberID, setUberID] = useState(null)
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const itemsPerPage = 6; // Adjust the number of items per page as needed
  const user = useAuth().user;

  const connectToUSBPrinter = async () => {
    try {
      const usbDevices = await navigator.usb.getDevices();
      const usbDevice = usbDevices[0]; // Assuming the USB printer is the first device

      if (!usbDevice) {
        console.log('No USB device found');
        const filters = [
          { vendorId: 0x0483, productId: 0x070B },
          { vendorId: 0x1FC9 }
        ]; // Replace with actual vendorId of the printer
        const options = { filters };
        const newUsbDevice = await navigator.usb.requestDevice(options);
        if (newUsbDevice) {
          await newUsbDevice.open();
          await newUsbDevice.selectConfiguration(1);
          await newUsbDevice.claimInterface(0);
          // await usbDevice.close();
          console.log('Connected to USB printer');
        }
      } else {
        await usbDevice.open();
        await usbDevice.selectConfiguration(1);
        await usbDevice.claimInterface(0);
        // await usbDevice.close();
        console.log('Connected to USB printer');
      }
    } catch (error) {
      console.log('Error connecting to USB printer:', error);
    }
  };

  const getOrderID = async()=>{
    try {
      const response = await fetch(Api.GET_ID, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${user.access_token}`
        }
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      // const maxCallNumber = data.maxCallNumber;
      setID(data.maxID+1)
      // Do something with maxCallNumber, like updating your component's state
    } catch (error) {
      console.error('Error fetching max call number:', error);
    }
  }
  useEffect(() => {
    connectToUSBPrinter();
    getOrderID()
  }, []);


  const handleSpiceLevelChange = (e, ItemIndex) => {
    const { value } = e.target;
    const updatedCartItems = cartItems.map((item, index) => {
      if (ItemIndex === index) {
        return { ...item, Spicy: value };
      }
      return item;
    });
    setCartItems(updatedCartItems);
  };

  const handlePaymentChange = (e) => {
    const { value } = e.target;
    if(value==='your_order' || value==='uber'){
      setTakeOut(false)
    }
    setPaymentValue(value);
  };

  const handleRemarkChange = (e, ItemIndex) => {
    const { value } = e.target;
    const updatedCartItems = cartItems.map((item, index) => {
      if (ItemIndex === index) {
        return { ...item, Remark: value };
      }
      return item;
    });
    setCartItems(updatedCartItems);
  };

  const handleRemoveFromOrder = (index) => {
    console.log(index)
    const updatedCartItems = cartItems.filter((_, i)=>i!==index);
    setCartItems(updatedCartItems);
  };

  // Calculate the total price of all items in the cart
  var totalPrice = cartItems.reduce((total, item) => total + item.Price * item.Amount, 0);
  if(totalPrice<0){
    totalPrice = 0;
  }

  // Determine the index range for the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = cartItems.slice(indexOfFirstItem, indexOfLastItem);
  const totalItems = cartItems.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  // Handle the "Next Page" button click
  const handleNextPage = () => {
    setEditItem(null)
    setCurrentPage(currentPage + 1);
  };

  // Handle the "Previous Page" button click
  const handlePreviousPage = () => {
    setEditItem(null)
    setCurrentPage(currentPage - 1);
  };

  const printData = async () => {
    // console.log(user.username)
    setButtonDisabled(true);
    const currentDate = new Date();

    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZone: 'Asia/Taipei'
    };

    const formattedDateTime = currentDate.toLocaleString('zh-TW', options);
    let fetchedCallNum = null;
      try {
        const usbDevices = await navigator.usb.getDevices();
        const usbDevice = usbDevices[0]; // Assuming the USB printer is the first device
        // console.log(usbDevices)
        if (!usbDevice) {
          console.log('No USB device found');
          const filters = [ { vendorId: 0x0483, productId:0x070B}, {vendorId:0x1FC9, productId:0x2016}]; // 替换为打印机的实际 vendorId
          const options = { filters };
          const usbDevice = await navigator.usb.requestDevice(options);
        }

        await usbDevice.open();
	      console.log(usbDevice)
        await usbDevice.selectConfiguration(1);
        await usbDevice.claimInterface(0);
        getOrderID()
        try {
          const response = await fetch(Api.GET_CALL_NUM, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${user.access_token}`
            }
          });

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const data = await response.json();
          // const maxCallNumber = data.maxCallNumber;
          fetchedCallNum = data.maxCallNumber+1
          console.log('Max Call Number:', fetchedCallNum);
          // Do something with maxCallNumber, like updating your component's state
        } catch (error) {
          console.error('Error fetching max call number:', error);
        }
        //open moneybox
        if(paymentValue==='cash'){
          var openMoneyBoxBytes =  new Uint8Array([27, 112, 0, 25, 250]);// Replace with the actual open money box bytes
          await usbDevice.transferOut(1, openMoneyBoxBytes);
          console.log('Open money box command sent to USB printer');
        }

        const title = `<CB>帳單明細${takeOut ? "[外帶]</CB>" : paymentValue==="your_order"?"[你訂]</CB>":"</CB>"}${paymentValue!=="your_order"&&paymentValue!=="uber"?`\n<CB>編號:${fetchedCallNum%300===0?300:fetchedCallNum%300}`:""}</CB>\n\n`;

        // Generate the data string
        const data = cartItems
        .map((value, _) =>{
          const spicy = value.Spicy ? `\n<B>辣度:${value.Spicy}</B>` : '';
          const remark = value.Remark ? `\n<B>備註: ${value.Remark}</B>` : ''; // Check if the remark exists
          const eggNote = value.Egg !== 0 ? `\n<B>雞蛋:${value.Egg}</B>\t+<M>${value.Egg*10}元</M>` : '';
          const ballNote = value.Ball !== 0 ? `\n<B>貢丸:${value.Ball}</B>\t+<M>${value.Ball*15}元</M>` : '';
          const noodleNote = value.Noodle !== false ? `\n<B>加麵</B>\t\t<M>+10元</M>` : '';
          // const itemPrice =  value.Price + (value.Egg * 10) + (value.Ball * 15) + (value.Noodle ? 10 : 0)
          return `<B>${value.ItemName}</B>\t<M>NT$ ${spicy}${eggNote}${ballNote}${noodleNote} ${remark}\n<B>數量:${value.Amount}</B>\n=======================\n`;
        })
        .join('');
        const Nidin = nidinInfo ? `\n<B>你訂名稱${nidinInfo}</B>` : ``
        const Uber = uberID ? `\n<B>Uber ID: ${uberID}</B>` : ``
        // Append the total amount
        const totalLine = `\n\n\n<M>總金額: ${totalPrice.toFixed(0)}</M>${Uber}${Nidin}\n${formattedDateTime} ID:${getID}\nCashier:${user.username} Pay:${paymentValue}\n\n`;
        let options = {
          beep: true,
          cut: true,
          tailingLine: true,
          encoding: 'GBK'
        }
        let result = EPToolkit.exchange_text(title+data+totalLine, options)
        let custom = EPToolkit.exchange_text(title+totalLine, options)
        //.cut('partial')
        await usbDevice.transferOut(1, result);
        await usbDevice.transferOut(1, custom);
        console.log('Data sent to USB printer');


        await usbDevice.releaseInterface(0);
        await usbDevice.close();
        setNidinInfo(null);
        setUberID(null);
        const cashier = user.username
        setCallNum(fetchedCallNum)
        const requestData = {
          data: JSON.stringify(cartItems),
          price: totalPrice,
          cashier: cashier,
          payment: paymentValue,
          isTakeOut: takeOut
        };
        requestData.callNum = paymentValue === "your_order"?null:fetchedCallNum
        // const Items = JSON.stringify(cartItems)

        const dbResponse = await fetch(Api.INSERT_ORDER, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${user.access_token}`
          },
          body: JSON.stringify(requestData)
        });
        
  
        if (dbResponse.ok) {
          console.log('Data inserted successfully');
        } else {
          console.error('Failed to inserted into db');
        }

      } catch (error) {
        console.log('Error printing data:', error);
      }finally{
        setCartItems([])
        navigate('/protected')
      }
    };

    const handleTakeOutChange = ()=>{
      setTakeOut(!takeOut)

    }

  return (
    <div className="order">
      <h2 className="order-title">訂單 ID:{getID}</h2>
      <div className='order-table-container'>
        <table className="order-table">
          <thead>
            <tr>
              <th>品名</th>
              <th>數量</th>
              <th>價格</th>
              <th>備註</th>
              <th>編輯</th>
              <th>刪除</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((item, index) => (
              <tr key={index}>
                <td>{item.ItemName}</td>
                <td>{item.Amount}</td>
                <td>
                  {item.Amount * item.Price}
                </td>
                {editItem===(currentPage-1)*itemsPerPage+index?
                  <td>
                    <Form.Control
                        className="order-custom-remark"
                        type="text"
                        placeholder="備註"
                        value={item.Remark}
                        onChange={(e)=>{handleRemarkChange(e, (currentPage-1)*itemsPerPage+index)}}
                      />
                  </td>
                :
                  <td>{item.Remark}</td>
                }
                <td>
                  {editItem===(currentPage-1)*itemsPerPage+index?
                  <button className='order-btn-finish' onClick={() => {setEditItem(null);setButtonDisabled(false);}}>
                    完成
                  </button>:
                  <button className='order-btn-edit' onClick={() => {setEditItem((currentPage-1)*itemsPerPage+index);setButtonDisabled(true);}}>
                    編輯
                  </button>
                  }
                </td>
                <td>
                  <button className='order-btn-delete' onClick={() => {handleRemoveFromOrder((currentPage-1)*itemsPerPage+index)}} disabled={isButtonDisabled}>
                    刪除
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>

        <div className="pagination">
          <button
            className="pagination-button"
            onClick={handlePreviousPage}
            disabled={currentPage === 1} // Disable the button if it's the first page
          >
            &lt;
          </button>

          <span className="pagination-info">
            Page {currentPage} of {totalPages}
          </span>

          <button
            className="pagination-button"
            onClick={handleNextPage}
            disabled={currentPage === totalPages || totalPages===0} // Disable the button if it's the last page
          >
            &gt;
          </button>
        </div>

        <div className="order-summary">
                  <span className="order-total">總價: {totalPrice}</span>
                  {/* <button className='moneybox_btn' onClick={openMoneyBox}>開錢盒</button> */}
                  {cartItems.length>0?
                  <>
                  <Form.Select className="custom-select" defaultValue={'cash'} onChange={(e)=>{handlePaymentChange(e)}}>
                  <option value="cash">現金</option>
                  <option value="linepay">LinePay</option>
                  <option value="All-Pay">全支付</option>
                  </Form.Select>
                  {paymentValue==='cash'?
                  <>
                    <lable className="custom-pay-lable">現金
                      <input className="custom-pay-input" type='number' onChange={(e)=>{
                        const {value} = e.target
                        setUserPay(value)
                      }}/>
                    </lable>

                    {
                      userPay-totalPrice>0?
                      <label className='change-lable'>找零{userPay - totalPrice}</label>
                      :null
                    }
                  </>:null
                  }{
                    <div className="order-checkbox-container">
                      <label className="order-checkbox-label">
                        <input
                          type="checkbox"
                          className="order-checkbox-input"
                          checked={takeOut}
                          onChange={handleTakeOutChange}
                          disabled={paymentValue === "your_order"}
                        />
                        外帶
                      </label>
                    </div>
                  }
                  <button className="checkout-button" onClick={printData} disabled={isButtonDisabled}>
                  結帳
                  </button>
                  </>
                  :
                  null}
                </div>
      </div>
    );
}
export default Order;
