import React, { useState, useRef, useEffect } from 'react';
import './css/ImageRenderer.css';
import Cart from './Cart';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Api from '../helpers/Api'
import { useNavigate } from 'react-router-dom';
import Marquee from './Marquee';
// import Food_Options from '../components/option-lable/Food_Options'
// import Drink_Options from '../components/option-lable/Drink_Options';
// import Vagetable_Options from '../components/option-lable/Vagetable_Options';
// import Soup_Options from '../components/option-lable/Soup_Options';
// import Rice_Options from '../components/option-lable/Rice_Options';
// import Nidin_Options from '../components/option-lable/Nidin_Options'
import Default_Options from '../components/option-lable/Default_Options'
import Options from './Options';
// import { PrinterThermalService } from '../helpers/escpos/PrinterThermalService';
function ImageRenderer({ menuData,setCartItems,cartItems}) {
  const navigate = useNavigate();
  const defaultForm = {
    ItemName : '',
    Price : '',
    // UberPrice : '',
    // Spicy: '',
    // Noodle: false,
    // Egg: 0,
    // Ball: 0,
    Remark: '',
    Amount: 1
  }
  const [activeLabel, setActiveLabel] = useState(Object.keys(menuData)[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [formValues, setFormValues] = useState(defaultForm);
  const [OptionsOpen, setOptionsOpen] = useState(null);
  const itemsPerPage = 8; // Number of items per page
  const formRef = useRef(null);

  const openOptions = (item) => {

    setFormValues((prevValues) => ({
      ...prevValues,
      ItemName: item.name,
      Price: item.price,
      // UberPrice: item.uberPrice
    }));
    setOptionsOpen(item);
  };

  const closeOptions = () => {
    setOptionsOpen(null);
  };


  const handleTabClick = (label) => {
    setActiveLabel(label);
    setCurrentPage(1); // Reset current page when switching labels
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  // Get the items to display on the current page
  const getCurrentPageItems = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return menuData[activeLabel].slice(startIndex, endIndex);
  };

  // Determine if there are more pages for the current label
  const hasMorePages = () => {
    const totalItems = menuData[activeLabel].length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    return currentPage < totalPages;
  };

  const handleAddToCart = (item) => {
    setCartItems((prevItems) => {
      const newItems = [ ...prevItems, item ];
      return newItems;
    });
  };

  const handleCheckout = () => {
    navigate('/order')
  };

  const handleCleanCart = ()=>{
    setCartItems([])
  }

  const handleInputChange = (e) => {
    const { value } = e.target;
    setFormValues({ ...formValues, Remark: value });
  };

  const handleCheckChange = (e) => {
    const { value } = e.target;
    setFormValues({...formValues, Spicy: value});
  };

  const handleEggChange = (e)=>{
    const { value } = e.target;
    if(value>=0){
      setFormValues({ ...formValues, Egg: value });
    }
  }
  const handleBallChange = (e)=>{
    const { value } = e.target;
    if(value>=0){
      setFormValues({ ...formValues, Ball: value });
    }
  }

  const handleAddNoodle = () => {
    setFormValues({ ...formValues, Noodle: !formValues.Noodle });
  };

  const handleAdd = () => {
    setFormValues({ ...formValues, Amount: formValues.Amount + 1 });
  }

  const handleSubtract = () => {
    if(formValues.Amount>1){
      setFormValues({ ...formValues, Amount: formValues.Amount - 1 });
    }
  }

  const handleSubmit = (e)=>{
    e.preventDefault();
    handleAddToCart(formValues)
    setOptionsOpen(null)
    formRef.current.reset();
    setFormValues(defaultForm);
  }

  const handleCancel = (e)=>{
    e.stopPropagation();
    formRef.current.reset();
    setOptionsOpen(null)
    // setSelectedItem(null);
    setFormValues(defaultForm);
  }

  const optionComponents = {
    Default: <Default_Options
              formValues={ formValues }
              handleAdd={ handleAdd }
              handleSubtract={ handleSubtract }
              handleInputChange={ handleInputChange }
            />
    // handleCheckChange={handleCheckChange}
    // formValues={formValues}
    // handleBallChange={handleBallChange}
    // handleEggChange={handleEggChange}
    // handleAddNoodle={handleAddNoodle}
    // handleInputChange={handleInputChange}
    // Default: <Food_Options
    //           handleCheckChange={ handleCheckChange }
    //           formValues={ formValues }
    //           handleBallChange={ handleBallChange }
    //           handleEggChange={ handleEggChange }
    //           handleAddNoodle={ handleAddNoodle }
    //           handleInputChange={ handleInputChange }
    //           handleAdd={ handleAdd }
    //           handleSubtract={ handleSubtract }
    //       />,
    // 飯類: <Rice_Options
    //       handleCheckChange={ handleCheckChange }
    //       formValues={ formValues }
    //       handleBallChange={ handleBallChange }
    //       handleEggChange={ handleEggChange }
    //       handleInputChange={ handleInputChange }
    //       handleAdd={ handleAdd }
    //       handleSubtract={ handleSubtract }
    //   />,
    // 湯類: <Soup_Options
    //     handleCheckChange={ handleCheckChange }
    //     formValues={ formValues }
    //     handleBallChange={ handleBallChange }
    //     handleEggChange={ handleEggChange }
    //     handleInputChange={ handleInputChange }
    //     handleAdd={ handleAdd }
    //     handleSubtract={ handleSubtract }
    // />,
    // 飲料: <Drink_Options
    //           formValues={ formValues }
    //           handleAdd={ handleAdd }
    //           handleSubtract={ handleSubtract }
    //         />,
    // 你訂: <Nidin_Options
    //   formValues={ formValues }
    //   handleAdd={ handleAdd }
    //   handleSubtract={ handleSubtract }
    // />,
    // 小菜: <Vagetable_Options
    //           handleCheckChange={handleCheckChange}
    //           formValues={formValues}
    //           handleInputChange={handleInputChange}
    //           handleAdd={ handleAdd }
    //           handleSubtract={ handleSubtract }
    //         />
  };

  return (
    <>
    <Marquee />
    <div className="image-renderer-wrapper">
      <div className="tab-container">
        {Object.keys(menuData).map((label, index) => (
          <button
            key={index}
            onClick={() => handleTabClick(label)}
            className={activeLabel === label ? 'active-tab' : 'tab'}
          >
            {label}
          </button>
        ))}
      </div>
      <div className="item-container">
        <div className="item-grid">
          {getCurrentPageItems().map((item, itemIndex) => (

            <div key={itemIndex} className='image-item'
              // onClick={() => handleSelect(item)}
              onClick={()=>openOptions(item)}
            >
              <div className="item-border">
                <div className="item-image-wrapper">
                  {item.image_path ? (
                    <img
                      src={`${Api.GET_IMG}/${item.image_path}`}
                      alt={`Image ${itemIndex}`}
                      className="item-image"
                    />
                  ) : (
                    <div className="placeholder-image">No Image Available</div>
                  )}
                </div>
                <div className="item-details">
                  <p className="item-name">{item.name}</p>
                  <p className="item-price">價格: {item.price}</p>
                </div>
              </div>
            </div>
          ))}
          <Options isOpen={OptionsOpen} onClose={closeOptions}>
            <div className='Options-lable'>{formValues.ItemName}</div>
            {/* <p>You can put any content here.</p> */}
            <div className='image-item selected'>
              <Form ref={formRef} onSubmit={handleSubmit}>
                {optionComponents[activeLabel] || (
                  optionComponents['Default']
                )}
                <div className="From-btn">
                  <button className="cancel-btn" onClick={handleCancel}>
                      取消
                  </button>
                  <button className="submit-btn" variant="primary" type="submit">
                    送出
                  </button>
                </div>
              </Form>
            </div>
          </Options>
        </div>
      </div>
      <div className="pagination-container">
        {currentPage > 1 && (
          <button className="prev-page-button" onClick={handlePrevPage}>
            上一頁
          </button>
        )}
        {hasMorePages() && (
          <button className="next-page-button" onClick={handleNextPage}>
            下一頁
          </button>
        )}
      </div>
    </div>
    <div className='cart-container'>
      <h2 className='cart-tittle'>帳單</h2>
      <div className='cart-border'>
      <Cart cartItems={cartItems} />
      </div>
      <div className='cartfn-div'>
        <button className='cleancart-btn' onClick={handleCleanCart}>清空</button>
        <button className='checkout-btn' onClick={handleCheckout}>結帳</button>
      </div>
    </div>

    </>
  );
}

export default ImageRenderer;
