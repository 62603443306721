import React,{ useEffect, useState } from 'react';
import Api from '../helpers/Api'
import { useAuth } from "../components/auth/AuthContext";
import "./css/History.css"
const History = () => {
  let auth = useAuth();
  const [historyInfo, setHistoryInfo] = useState([])
  useEffect(() => {
    async function fetchHistory() {
      try {
        const token = auth.user.access_token; // Replace with your actual access token
        const response = await fetch(Api.GET_ORDER_HISTORY, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        if (response.ok) {
          const data = await response.json();
          
          if (data) {
            setHistoryInfo(data)
          }
          // console.log(data)
        } else {
          console.error('Failed to fetch total price');
        }
      } catch (error) {
        console.error('Error fetching total price:', error);
      }
    }

    fetchHistory();
  }, []); // Empty dependency array means this effect will run only once on mount

  function getPaymentLabel(payment) {
    switch (payment) {
      case 'cash':
        return '現金支付';
      case 'linepay':
        return 'LinePay';
      case 'your_order':
        return '你訂';
      // Add more cases for other payment types if needed
      default:
        return payment;
    }
  }
  
  return (
    <div>
  <table className="history-table">
    <thead>
      <tr>
        <th>時間</th>
        <th>交易序號</th>
        <th>金額</th>
        <th>支付方式</th>
        <th>收銀員</th>
        <th>狀態</th>
      </tr>
    </thead>
    <tbody>
      {historyInfo.map(item => (
        <tr key={item.id} className={item.status? '' :'deleted-row'}>
          <td>{item.time}</td>
          <td>{item.id}</td>
          <td>{item.price}</td>
          <td>{getPaymentLabel(item.payment)}</td>
          <td>{item.cashier}</td>
          <td style={{ color: item.status? 'black' : 'red' }}>
            {item.status?'成功':'取消'}
          </td>
        </tr>
      ))}
    </tbody>
  </table>
</div>

  );
};

export default History;