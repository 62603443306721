import React, { useEffect, useState } from 'react';
import './css/Cart.css';

function Cart({ cartItems }) {
  const [flashIndex, setFlashIndex] = useState(-1);

  // Add a flash effect to the newly added item
  useEffect(() => {
    if (cartItems.length > 0) {
      setFlashIndex(0);
      const timeout = setTimeout(() => {
        setFlashIndex(-1);
      }, 500); // Set the duration of the flash effect (in milliseconds)
      return () => clearTimeout(timeout);
    }
  }, [cartItems]);

  return (
    <div className="cart-item-container">
      {cartItems
        .slice(0)
        .reverse()
        .map((item, index) => (
          <div
            key={index}
            className={`cart-item ${index === flashIndex ? 'flash' : ''}`}
          >
            <h2>{item.ItemName}</h2>
            {item.Remark && (
              <div className="amount-item-left">備註: {item.Remark}</div>
            )}
            <div className="amount-item-right">數量: {item.Amount}</div>
          </div>
        ))}
    </div>
  );
}

export default Cart;
