import  React from "react";
import {AuthContext} from './AuthContext'
import Api from '../../helpers/Api'
// import  {fakeAuthProvider} from './fakeAuthProvider'
export default function AuthProvider({ children }) {
  const [user, setUser] = React.useState(() => {
    const storedAuth = localStorage.getItem("auth");
    return storedAuth ? JSON.parse(storedAuth) : null;
  });

  let signin = (username, password,  callback) => {
    fetch(Api.AUTH, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password }),
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          callback(false);
          throw new Error('Login request failed');
        }
      })
      .then(data => {
        if (data.success === true) {
          const profile = {'username':username, 'access_token':data.token, 'role':data.role}
          setUser(profile)
          localStorage.setItem("auth", JSON.stringify(profile));
          callback(true);
        }
        else{
          callback(false);
        }
      })
      .catch(error => {
        // Handle any network or server errors
        console.error(error);
        callback(false);
      });
    
  };

  let signout = (callback) => {
      setUser(null);
      localStorage.removeItem("auth");
      callback();
  };

  let value = { user, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
