import React,{ useEffect, useState } from 'react';
import Api from '../helpers/Api'
import { useAuth } from "../components/auth/AuthContext";
import './css/Account.css';
const Account = () => {
  let auth = useAuth();
  const [totalInfo, setTotalInfo] = useState([])
  useEffect(() => {
    async function fetchTotalPriceToday() {
      try {
        const token = auth.user.access_token; // Replace with your actual access token
        const response = await fetch(`${Api.GET_TOTAL_PRICE_TODAY}/${auth.user.username}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        if (response.ok) {
          const data = await response.json();
          
          if (data) {
            setTotalInfo(data)
          }
          // console.log(data)
        } else {
          console.error('Failed to fetch total price');
        }
      } catch (error) {
        console.error('Error fetching total price:', error);
      }
    }

    fetchTotalPriceToday();
  }, []); // Empty dependency array means this effect will run only once on mount

  function getPaymentLabel(payment) {
    switch (payment) {
      case 'cash':
        return '現金支付';
      case 'linepay':
        return 'LinePay';
      case 'your_order':
        return '你訂';
      // Add more cases for other payment types if needed
      default:
        return payment;
    }
  }
  
  return (
    <div>
  <table className="account-table">
    <thead>
      <tr>
        <th>支付方式</th>
        <th>總比數</th>
        <th>總金額</th>
      </tr>
    </thead>
    <tbody>
      {totalInfo.map(item => (
        <tr key={item.payment}>
          <td>{getPaymentLabel(item.payment)}</td>
          <td>{item.totalTransactions}</td>
          <td>{item.totalPrice}</td>
        </tr>
      ))}
    </tbody>
  </table>
</div>

  );
};

export default Account;
